import { atom } from "recoil";
import { Question, QuestionType } from "../data/question";

export type AudioChunksState = {
  mediaRecorder: MediaRecorder | null;
  state: "inactive" | "recording" | "paused";
};

export const audioRecorderState = atom<AudioChunksState>({
  key: "audio_recorder_state",
  default: {
    mediaRecorder: null,
    state: "inactive",
  },
});

export const textAnswerState = atom<string>({
  key: "text_answer_state",
  default: "",
});

export const questionState = atom<Question | undefined>({
  key: "selected_question",
  default: undefined,
  effects: [
    ({ onSet, setSelf }) => {
      const stored = localStorage.getItem("selected_question") ?? "";
      setSelf(
        JSON.parse(stored === "undefined" || stored === "" ? "{}" : stored)
      );

      onSet((value) => {
        localStorage.setItem("selected_question", JSON.stringify(value));
      });
    },
  ],
});

export const commonQuestionsState = atom<Array<Question>>({
  key: "common_questions_state",
  default: [],
  effects: [
    ({ onSet, setSelf }) => {
      const stored = localStorage.getItem("common_questions_state") ?? "";
      setSelf(
        JSON.parse(stored === "undefined" || stored === "" ? "[]" : stored)
      );

      onSet((value) => {
        localStorage.setItem("common_questions_state", JSON.stringify(value));
      });
    },
  ],
});

export const questionTypeState = atom<QuestionType>({
  key: "question_type_state",
  default: "both",
  effects: [
    ({ onSet, setSelf }) => {
      setSelf(localStorage.getItem("question_type_state") ?? ("both" as any));

      onSet((value) => {
        localStorage.setItem("question_type_state", value.toString());
      });
    },
  ],
});

export const threadState = atom<string>({
  key: "thread_state",
  default: "",
  effects: [
    ({ onSet, setSelf }) => {
      setSelf(localStorage.getItem("thread_state") ?? "");

      onSet((value) => {
        localStorage.setItem("thread_state", value ?? "");
      });
    },
  ],
});
