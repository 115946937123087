import { useEffect, useRef } from "react";

export default function useOnInitialMount(onMount: () => void): void {
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      onMount();
    }
  });

  // Instead of checking a dependency array with a callback that may not be memoized
  // let's just run this effect after every commit and check the boolean, which should be lighter-weight
}
