import { ThemeProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { PostHogProvider } from 'posthog-js/react';
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import "./App.css";

import posthog from "./analytics/posthog";
import { useValidateProtocol } from "./hooks/useValidateProtocol";
import { AppRoutes } from "./routes/routes";
import { theme } from "./styles/theme";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchInterval: 1000 * 60, retry: 0 } },
});

function App() {
  useValidateProtocol({ host: "practiceinterviews.com" });

  return (
    <RecoilRoot>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AppRoutes />
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
        <ToastContainer />
      </PostHogProvider>
    </RecoilRoot>
  );
}

export default App;
