import { atom } from "recoil";

type SupportVisibilityType = "hidden" | "minimized" | "maximized";

export const supportVisibilityState = atom<SupportVisibilityType>({
  key: "support_visibility_state",
  default: "hidden",
});

export const homeButtonClickedState = atom<boolean>({
  key: "home_button_clicked_state",
  default: false,
});
