import { usePostHog } from "posthog-js/react";
import { useRecoilState } from "recoil";
import { useOnChange } from "../hooks/useOnChange";
import { userState } from "../states/auth";

export function usePostHogInitiate() {
  const [user] = useRecoilState(userState);
  const posthog = usePostHog();

  useOnChange({
    value: user,
    defaultValue: {},
    onChange: () => {
      if (user?.id) {
        posthog?.identify(user.id, {
          email: user.email,
          name: `${user.firstName} ${user.lastName} `,
        })
      } else {
        posthog?.identify("anonymous", {});
      }
    }
  })
}