import { useEffect } from "react";
import usePrevious from "./usePrevious";

type Params = {
  value: any;
  defaultValue: any;
  skipDefaultCheck?: boolean;
  onChange: () => void;
  onUnchanged?: () => void;
};

export function useOnChange({
  value,
  defaultValue,
  skipDefaultCheck = false,
  onChange,
  onUnchanged,
}: Params): void {
  const previousValue = usePrevious(value) ?? defaultValue;

  useEffect(() => {
    if (
      skipDefaultCheck &&
      JSON.stringify(previousValue) === JSON.stringify(defaultValue)
    ) {
      return;
    }

    if (JSON.stringify(value) !== JSON.stringify(previousValue)) {
      onChange && onChange();
    } else {
      onUnchanged && onUnchanged();
    }
  }, [
    value,
    previousValue,
    onChange,
    onUnchanged,
    skipDefaultCheck,
    defaultValue,
  ]);
}
