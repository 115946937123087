import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { useRecoilValue } from "recoil";
import { clientState } from "../states/auth";

interface Params {
  actionName: string;
}

export function useGetUserAction(
  options?: UseMutationOptions<
    { data: { isCompleted: boolean } },
    AxiosError,
    Params
  >
) {
  const client = useRecoilValue(
    clientState({
      contentType: "application/json",
    })
  );

  return useMutation<{ data: { isCompleted: boolean } }, AxiosError, Params>(
    (params) =>
      client.get(`/userActionMappings?actionName=${params.actionName}`),
    {
      ...options,
    }
  );
}
