import { LicenseInfo } from "@mui/x-license-pro";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./analytics/amplitude";
import "./analytics/hotjar";
import "./analytics/sentry";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_PRO_LICENSE_KEY || "";
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
