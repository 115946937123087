import { Constants } from "../data/constants";

export const BASE_URL: string = window.origin;

export function getAPIBaseURL(apiVersion: number = 2): string {
  const baseURL = isStage()
    ? Constants.API_BASE_URLS.stage
    : isProduction()
    ? Constants.API_BASE_URLS.production
    : Constants.API_BASE_URLS.dev;

  return `${baseURL}/api/v${apiVersion}`;
}

export function getSocketBaseURL(): string {
  const baseURL = isStage()
    ? Constants.SOCKET_BASE_URLS.stage
    : isProduction()
    ? Constants.SOCKET_BASE_URLS.production
    : Constants.SOCKET_BASE_URLS.dev;

  return baseURL ?? "";
}

export function isLocalhost(): boolean {
  return BASE_URL.indexOf("localhost:3000") >= 0;
}

export function isProduction(): boolean {
  return BASE_URL.indexOf("app.practiceinterviews.com") >= 0;
}

export function isDev(): boolean {
  return BASE_URL.indexOf("dev.practiceinterviews.com") >= 0;
}

export function isStage(): boolean {
  return BASE_URL.indexOf("stage.practiceinterviews.com") >= 0;
}

export function isSafari(): boolean {
  const ua = navigator.userAgent;
  return ua.includes("Safari") && !ua.includes("Chrome");
}

export function isChrome(): boolean {
  const userAgentString = navigator.userAgent;
  return userAgentString.indexOf("Chrome") > -1;
}

export function isFirefox(): boolean {
  const userAgentString = navigator.userAgent;
  return userAgentString.indexOf("Firefox") > -1;
}
