import { atom } from "recoil";

export type ScoresType = {
  [key: string]: number;
} | null;

export const scoresState = atom<ScoresType>({
  key: "score",
  default: null,
});
