import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Question, QuestionType } from "../data/question";
import { clientState } from "../states/auth";
import { commonQuestionsState } from "../states/recording";

interface Params {
  questionType: QuestionType;
}

export function useQuestionByType(
  options?: UseMutationOptions<
    { data: { success: boolean; data: Question[] } },
    AxiosError,
    Params
  >
) {
  const setCommonQuestions = useSetRecoilState(commonQuestionsState);
  const client = useRecoilValue(
    clientState({
      contentType: "application/json",
      apiVersion: 1,
    })
  );

  return useMutation<
    { data: { success: boolean; data: Question[] } },
    AxiosError,
    Params
  >((params) => client.get(`/questions?questionType=${params.questionType}`), {
    ...options,
    onSuccess(res) {
      const orderedQuestions = [
        "Tell me about yourself?",
        "Why do you want to work at this company?",
        "Why should we hire you?",
        "What are your greatest weaknesses?",
      ];

      const sortedQuestions = res.data.data.sort((a, b) => {
        const indexA = orderedQuestions.indexOf(a.text);
        const indexB = orderedQuestions.indexOf(b.text);

        if (indexA < indexB) return -1;
        if (indexA > indexB) return 1;

        return 0;
      });
      setCommonQuestions(sortedQuestions);
    },
  });
}
